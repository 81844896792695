<template>
  <v-card
    elevation="0"
    class="mx-auto my-auto animate__animated animate__fadeIn"
    width="350"
  >
    <v-layout justify-center>
      <v-img
        class="mt-5"
        max-height="300"
        max-width="300"
        src="@/assets/logo.svg"
      ></v-img>
    </v-layout>

    <v-card-text>
      <v-row class="ma-5">
        <v-text-field label="ID" v-model="id" autocomplete="on"></v-text-field>
      </v-row>
      <v-row class="ma-5">
        <v-text-field
          label="パスワード"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          @keypress.enter="login()"
        ></v-text-field>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row justify="center">
        <v-btn color="primary" @click="login()">ログイン</v-btn>
      </v-row>
    </v-card-actions>
    <v-card-actions>
      <v-row justify="center" class="ma-5">
        <ResetPassword></ResetPassword>
      </v-row>
    </v-card-actions>
    <v-snackbar v-show="msg" top v-model="snackbar" timeout="3000">
      {{ msg }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    id: "",
    password: "",
    show1: false,
    snackbar: false,
    msg: "",
  }),
  methods: {
    login() {
      this.axios
        .post(process.env.VUE_APP_API_URL + "/login", {
          id: this.id,
          password: this.password,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push("/");
            }
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        });
    },
  },
  components: {
    ResetPassword: () => import("@/components/login/ResetPassword"),
  },
  mounted() {
    this.axios
      .post(process.env.VUE_APP_API_URL + "/session-check", {})
      .then((response) => {
        if (response.data.result == "OK") {
          this.$router.push("/");
        }
      });
  },
};
</script>
